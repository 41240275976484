import { Link, useStaticQuery, graphql } from "gatsby";
import React, { useState, useEffect, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import Button from "react-bootstrap/Button";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import HeroOther from "../components/hero-other";
import ReactPlayer from "react-player";
import ImageCarousel from "../components/changingImage";
import { checkPropertiesForNull } from "../utils/object";
import { SafeHtmlParser } from "../components/safe-html-parser";
import ScorecardModal from "../components/scorecardModal";
import { groupArrayInPairs } from "../utils/object";
import uuid4 from "uuid4";

const CourseOverviewPage = () => {
  const data = useStaticQuery(graphql`
    query {
      pageData: wpPage(slug: { eq: "course-overview" }) {
        seoFieldGroups {
          localBusinessSchema
          metaDescription
          metaTitle
          openGraphDescription
          openGraphTitle
          productSchema
          image {
            altText
            publicUrl
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP]
                  quality: 100
                  transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                  layout: CONSTRAINED
                  placeholder: BLURRED
                )
                original {
                  width
                  height
                }
              }
            }
          }
        }
        courseOverviewPageFieldGroups {
          courseBannerSection {
            heading
            subHeading
            description
            cta2 {
              target
              title
              url
            }
            cta1 {
              target
              title
              url
            }
            backgroundImage {
              altText
              localFile {
                publicURL
                childImageSharp {
                  original {
                    height
                    width
                  }
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
          courseVideoSection {
            heading
            description
            video {
              altText
              mediaItemUrl
            }
          }
          tanishqSection {
            heading
            description
            imageGallery {
              altText
              localFile {
                publicURL
                childImageSharp {
                  original {
                    height
                    width
                  }
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
          theCourseSection {
            description
            heading
            imageGallery {
              altText
              localFile {
                publicURL
                childImageSharp {
                  original {
                    height
                    width
                  }
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
          thePantrySection {
            heading
            description
            imageGallery {
              altText
              localFile {
                publicURL
                childImageSharp {
                  original {
                    height
                    width
                  }
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
          whatToExpectSection {
            heading
            subHeading
            description
            cta1 {
              target
              title
              url
            }
            cta2 {
              target
              title
              url
            }
            scorecardImage {
              altText
              localFile {
                publicURL
                childImageSharp {
                  original {
                    height
                    width
                  }
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
        }
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  const siteUrl = data.site.siteMetadata.siteUrl;
  // const pluginImage = getImage(data.heroImg.childImageSharp.gatsbyImageData);

  const [currentSlides, setCurrentSlides] = useState([0, 0, 0, 0, 0, 0, 0]);

  const updateSlides = (index) => {
    setCurrentSlides((prevSlides) => {
      const updatedSlides = [...prevSlides];
      updatedSlides[index] = updatedSlides[index] === 1 ? 0 : 1;
      return updatedSlides;
    });

    setTimeout(() => {
      const nextIndex = (index + 1) % currentSlides.length;
      updateSlides(nextIndex);
    }, 1500);
  };

  useEffect(() => {
    updateSlides(0);
  }, []);

  const {
    pageData: { seoFieldGroups, courseOverviewPageFieldGroups },
    site,
  } = data;

  const {
    courseBannerSection,
    courseVideoSection,
    tanishqSection,
    theCourseSection,
    thePantrySection,
    whatToExpectSection,
  } = courseOverviewPageFieldGroups;

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "Course Overview",
        item: {
          url: `${siteUrl}/course-overview`,
          id: `${siteUrl}/course-overview`,
        },
      },
    ],
  };

  const groupedImages = groupArrayInPairs(theCourseSection.imageGallery);

  let gallery1 = [],
    gallery2 = [],
    gallery3 = [],
    pentryGallery1 = [],
    pentryGallery2 = [],
    pentryGallery3 = [],
    tanishqGallery1 = [],
    tanishqGallery2 = [],
    tanishqGallery3 = [];

  if (groupedImages.length > 0) {
    gallery1 = groupedImages.slice(0, 3);
    gallery2 = groupedImages.slice(3, 5);
    gallery3 = groupedImages.slice(5, 7);
  }

  if (thePantrySection.imageGallery.length > 0) {
    pentryGallery1 = thePantrySection.imageGallery.slice(0, 2);
    pentryGallery2 = thePantrySection.imageGallery.slice(2, 4);
    pentryGallery3 = thePantrySection.imageGallery.slice(4, 7);
  }

  if (tanishqSection.imageGallery.length > 0) {
    tanishqGallery1 = tanishqSection.imageGallery.slice(0, 2);
    tanishqGallery2 = tanishqSection.imageGallery.slice(2, 4);
    tanishqGallery3 = tanishqSection.imageGallery.slice(4, 7);
  }

  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>

      <GatsbySeo
        title={seoFieldGroups?.metaTitle}
        description={seoFieldGroups?.metaDescription}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/course-overview`,
          title: `${seoFieldGroups?.openGraphTitle}`,
          description: `${seoFieldGroups?.openGraphDescription}`,
          images: [
            {
              url: `${seoFieldGroups?.image?.localFile.publicURL}`,
              width: `${seoFieldGroups?.image?.localFile.childImageSharp.original.width}`,
              height: `${seoFieldGroups?.image?.localFile.childImageSharp.original.height}`,
              alt: `${seoFieldGroups?.image?.altText}`,
            },
          ],
        }}
      />

      <div>
        <Layout>
          {courseBannerSection &&
            !checkPropertiesForNull(courseBannerSection, ["heading"]) && (
              <HeroOther
                smallHeading={courseBannerSection?.subHeading}
                title={courseBannerSection?.heading}
                externalLink={courseBannerSection?.cta1.target === "_blank"}
                button1Text={courseBannerSection?.cta1?.title}
                button1Link={courseBannerSection?.cta1?.url}
                button2Text={courseBannerSection?.cta2?.title}
                button2Link={courseBannerSection?.cta2?.url}
                description={
                  <>
                    {courseBannerSection?.description && (
                      <SafeHtmlParser
                        htmlContent={courseBannerSection?.description}
                      />
                    )}
                  </>
                }
                backgroundImage={
                  courseBannerSection.backgroundImage.localFile.childImageSharp
                    .gatsbyImageData
                }
              />
            )}
          {whatToExpectSection &&
            !checkPropertiesForNull(whatToExpectSection, ["heading"]) && (
              <section className="py-4 py-md-5 py-lg-7 bg-primary">
                <Container>
                  <Row className="justify-content-center">
                    <Col className="text-center" lg={8}>
                      {whatToExpectSection.subHeading && (
                        <p className="text-uppercase text-center text-secondary pb-2 mb-0 ssp-semibold">
                          {whatToExpectSection?.subHeading}
                        </p>
                      )}

                      <h2 className="line-heading text-white mb-4 fs-1">
                        {whatToExpectSection?.heading}
                      </h2>
                      {whatToExpectSection?.description && (
                        <div className="text-white text-center">
                          <SafeHtmlParser
                            htmlContent={whatToExpectSection?.description}
                          />
                        </div>
                      )}
                      {whatToExpectSection.cta1 &&
                        whatToExpectSection.cta1.url && (
                          <Button
                            style={{ borderRadius: "0px" }}
                            variant="white"
                            as={Link}
                            to={whatToExpectSection.cta1.url}
                            className="py-3 w-100 text-primary w-md-auto mb-4 mb-md-0 mt-4 px-6 me-md-4 "
                          >
                            {whatToExpectSection.cta1?.title}
                          </Button>
                        )}
                      <ScorecardModal
                        image={whatToExpectSection.scorecardImage}
                        buttonText={whatToExpectSection.cta2?.title}
                        green
                      />
                    </Col>
                  </Row>
                </Container>
              </section>
            )}
          {courseVideoSection &&
            !checkPropertiesForNull(courseVideoSection, ["heading"]) && (
              <section className="py-4 py-md-5 py-lg-7">
                <Container>
                  <Row>
                    <Col lg={6}>
                      <h2 className="text-primary mb-4 fs-1">
                        {courseVideoSection?.heading}
                      </h2>
                      {courseVideoSection.description && (
                        <div className="mb-4">
                          <SafeHtmlParser
                            htmlContent={courseVideoSection.description}
                          />
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <ReactPlayer
                        controls
                        className="w-100 h-auto"
                        url={courseVideoSection?.video?.mediaItemUrl}
                      />
                    </Col>
                  </Row>
                </Container>
              </section>
            )}
          {theCourseSection &&
            !checkPropertiesForNull(theCourseSection, ["heading"]) && (
              <section className="py-4 py-md-5 py-lg-7">
                <Container>
                  <Row>
                    <Col>
                      <h2 className="text-primary mb-4 fs-1">
                        {theCourseSection?.heading}
                      </h2>
                      {theCourseSection?.description && (
                        <div className="mb-4">
                          <SafeHtmlParser
                            htmlContent={theCourseSection?.description}
                          />
                        </div>
                      )}
                    </Col>
                  </Row>
                  {theCourseSection.imageGallery &&
                    theCourseSection.imageGallery.length > 0 && (
                      <>
                        <Row className="d-none d-md-flex">
                          <Col md={6} lg={4} className="g-3">
                            {gallery1.map((pair, i) => (
                              <ImageCarousel
                                images={pair}
                                height="310px"
                                activeslide={currentSlides[i]}
                                key={uuid4()}
                              />
                            ))}
                          </Col>
                          <Col md={6} lg={4} className="g-3">
                            {gallery2.map((pair, i) => (
                              <ImageCarousel
                                images={pair}
                                height={i === 0 ? "310px" : "636px"}
                                activeslide={currentSlides[i]}
                                key={uuid4()}
                              />
                            ))}
                          </Col>
                          <Col lg={4} className="g-3 d-none d-lg-block">
                            {gallery3.map((pair, i) => (
                              <ImageCarousel
                                images={pair}
                                height={i === 0 ? "636px" : "310px"}
                                activeslide={currentSlides[i]}
                                key={uuid4()}
                              />
                            ))}
                          </Col>
                        </Row>

                        <div className="d-md-none d-grid gallery-wrapper gap-3">
                          {theCourseSection.imageGallery
                            .slice(0, 6)
                            .map((item) => (
                              <div className="gallery-item" key={uuid4()}>
                                <GatsbyImage
                                  className="w-100 mb-3"
                                  style={{ height: "100%" }}
                                  image={
                                    item.localFile.childImageSharp
                                      .gatsbyImageData
                                  }
                                  alt={item.altText}
                                />
                              </div>
                            ))}
                        </div>
                      </>
                    )}
                </Container>
              </section>
            )}

          {/* <section className="py-4 py-md-5 py-lg-7">
            <Container>
              <Row>
                <Col lg={6}>
                  <h2 className="text-primary mb-4 fs-1">The course</h2>
                  <p className="mb-4">
                    Our golf course is an all-year-round gem, welcoming golfers
                    of all abilities. With a par of 70, it provides an enjoyable
                    challenge for both newcomers and seasoned players.
                    Impeccably maintained fairways and greens ensure an
                    exceptional golfing experience all year round. Join us for a
                    round anytime and relish the timeless joy of golf.
                  </p>
                </Col>
              </Row>
              <Row className="d-none d-md-flex">
                <Col md={6} lg={4} className="g-3">
                  <GatsbyImage
                    style={{ height: "636px" }}
                    className="w-100 mb-3 d-lg-none"
                    image={
                      data.gallery6.localFile.childImageSharp.gatsbyImageData
                    }
                    alt={data.gallery6.altText}
                  />
                  <GatsbyImage
                    className="w-100 mb-3"
                    style={{ height: "310px" }}
                    image={
                      data.gallery1.localFile.childImageSharp.gatsbyImageData
                    }
                    alt={data.gallery1.altText}
                  />
                  <GatsbyImage
                    className="w-100 mb-3"
                    style={{ height: "310px" }}
                    image={
                      data.gallery2.localFile.childImageSharp.gatsbyImageData
                    }
                    alt={data.gallery2.altText}
                  />
                  <GatsbyImage
                    className="w-100 mb-3 d-none d-lg-block"
                    style={{ height: "310px" }}
                    image={
                      data.gallery3.localFile.childImageSharp.gatsbyImageData
                    }
                    alt={data.gallery3.altText}
                  />
                </Col>
                <Col md={6} lg={4} className="g-3">
                  <GatsbyImage
                    className="w-100 mb-3 d-lg-none"
                    style={{ height: "310px" }}
                    image={
                      data.gallery3.localFile.childImageSharp.gatsbyImageData
                    }
                    alt={data.gallery3.altText}
                  />
                  <GatsbyImage
                    className="w-100 mb-3"
                    style={{ height: "310px" }}
                    image={
                      data.gallery4.localFile.childImageSharp.gatsbyImageData
                    }
                    alt={data.gallery4.altText}
                  />
                  <GatsbyImage
                    style={{ height: "636px" }}
                    className="w-100 mb-3"
                    image={
                      data.gallery5.localFile.childImageSharp.gatsbyImageData
                    }
                    alt={data.gallery5.altText}
                  />
                </Col>
                <Col lg={4} className="g-3 d-none d-lg-block">
                  <GatsbyImage
                    style={{ height: "636px" }}
                    className="w-100 mb-3"
                    image={
                      data.gallery6.localFile.childImageSharp.gatsbyImageData
                    }
                    alt={data.gallery6.altText}
                  />
                  <GatsbyImage
                    className="w-100 mb-3"
                    style={{ height: "310px" }}
                    image={
                      data.gallery7.localFile.childImageSharp.gatsbyImageData
                    }
                    alt={data.gallery7.altText}
                  />
                </Col>
              </Row>
              <Row className=" d-md-none">
                <Col xs={6} lg={4} className="g-3">
                  <GatsbyImage
                    style={{ height: "336px" }}
                    className="w-100 mb-3 d-lg-none"
                    image={
                      data.gallery6.localFile.childImageSharp.gatsbyImageData
                    }
                    alt={data.gallery6.altText}
                  />
                  <GatsbyImage
                    className="w-100 mb-3"
                    style={{ height: "160px" }}
                    image={
                      data.gallery1.localFile.childImageSharp.gatsbyImageData
                    }
                    alt={data.gallery1.altText}
                  />
                  <GatsbyImage
                    className="w-100 mb-3"
                    style={{ height: "160px" }}
                    image={
                      data.gallery2.localFile.childImageSharp.gatsbyImageData
                    }
                    alt={data.gallery2.altText}
                  />
                </Col>
                <Col xs={6} lg={4} className="g-3">
                  <GatsbyImage
                    className="w-100 mb-3 d-lg-none"
                    style={{ height: "160px" }}
                    image={
                      data.gallery3.localFile.childImageSharp.gatsbyImageData
                    }
                    alt={data.gallery3.altText}
                  />
                  <GatsbyImage
                    className="w-100 mb-3"
                    style={{ height: "160px" }}
                    image={
                      data.gallery4.localFile.childImageSharp.gatsbyImageData
                    }
                    alt={data.gallery4.altText}
                  />
                  <GatsbyImage
                    style={{ height: "336px" }}
                    className="w-100 mb-3"
                    image={
                      data.gallery5.localFile.childImageSharp.gatsbyImageData
                    }
                    alt={data.gallery5.altText}
                  />
                </Col>
              </Row>
            </Container>
          </section> */}
          {thePantrySection &&
            !checkPropertiesForNull(thePantrySection, ["heading"]) && (
              <section className="py-4 py-md-5 py-lg-7">
                <Container>
                  <Row>
                    <Col lg={6}>
                      <h2 className="text-primary mb-4 fs-1">
                        {thePantrySection?.heading}
                      </h2>
                      {thePantrySection?.description && (
                        <div className="mb-4">
                          <SafeHtmlParser
                            htmlContent={thePantrySection?.description}
                          />
                        </div>
                      )}
                    </Col>
                  </Row>
                  {thePantrySection.imageGallery &&
                    thePantrySection.imageGallery.length > 0 && (
                      <>
                        <Row className="d-none d-md-flex">
                          {pentryGallery1.length > 0 && (
                            <Col md={6} lg={4} className="g-3">
                              {pentryGallery1.map((item, i) => (
                                <GatsbyImage
                                  className="w-100 mb-3"
                                  style={{
                                    height: i === 1 ? "636px" : "310px",
                                  }}
                                  image={
                                    item.localFile.childImageSharp
                                      .gatsbyImageData
                                  }
                                  alt={item.altText}
                                />
                              ))}
                            </Col>
                          )}

                          {pentryGallery2.length > 0 && (
                            <Col md={6} lg={4} className="g-3">
                              {pentryGallery2.map((item, i) => (
                                <GatsbyImage
                                  className="w-100 mb-3"
                                  style={{
                                    height: i === 0 ? "636px" : "310px",
                                  }}
                                  image={
                                    item.localFile.childImageSharp
                                      .gatsbyImageData
                                  }
                                  alt={item.altText}
                                  key={uuid4()}
                                />
                              ))}
                            </Col>
                          )}

                          {pentryGallery3.length > 0 && (
                            <Col md={6} lg={4} className="g-3">
                              {pentryGallery3.map((item) => (
                                <GatsbyImage
                                  className="w-100 mb-3"
                                  style={{ height: "310px" }}
                                  image={
                                    item.localFile.childImageSharp
                                      .gatsbyImageData
                                  }
                                  alt={item.altText}
                                  key={uuid4()}
                                />
                              ))}
                            </Col>
                          )}
                        </Row>

                        <div className="d-md-none d-grid gallery-wrapper gap-3">
                          {thePantrySection.imageGallery
                            .slice(0, 6)
                            .map((item) => (
                              <div className="gallery-item" key={uuid4()}>
                                <GatsbyImage
                                  className="w-100 mb-3"
                                  style={{ height: "100%" }}
                                  image={
                                    item.localFile.childImageSharp
                                      .gatsbyImageData
                                  }
                                  alt={item.altText}
                                />
                              </div>
                            ))}
                        </div>
                      </>
                    )}
                </Container>
              </section>
            )}
          {tanishqSection &&
            !checkPropertiesForNull(tanishqSection, ["heading"]) && (
              <section className="py-4 py-md-5 py-lg-7">
                <Container>
                  <Row>
                    <Col lg={6}>
                      <h2 className="text-primary mb-4 fs-1">
                        {tanishqSection?.heading}
                      </h2>
                      {tanishqSection?.description && (
                        <div className="mb-4">
                          <SafeHtmlParser
                            htmlContent={tanishqSection?.description}
                          />
                        </div>
                      )}
                    </Col>
                  </Row>
                  {tanishqSection.imageGallery &&
                    tanishqSection.imageGallery.length > 0 && (
                      <>
                        <Row className="d-none d-md-flex">
                          {tanishqGallery1.length > 0 && (
                            <Col md={6} lg={4} className="g-3">
                              {tanishqGallery1.map((item, i) => (
                                <GatsbyImage
                                  className="w-100 mb-3"
                                  style={{
                                    height: i === 1 ? "636px" : "310px",
                                  }}
                                  image={
                                    item.localFile.childImageSharp
                                      .gatsbyImageData
                                  }
                                  alt={item.altText}
                                  key={uuid4()}
                                />
                              ))}
                            </Col>
                          )}

                          {tanishqGallery2.length > 0 && (
                            <Col md={6} lg={4} className="g-3">
                              {tanishqGallery2.map((item, i) => (
                                <GatsbyImage
                                  className="w-100 mb-3"
                                  style={{
                                    height: i === 0 ? "636px" : "310px",
                                  }}
                                  image={
                                    item.localFile.childImageSharp
                                      .gatsbyImageData
                                  }
                                  alt={item.altText}
                                  key={uuid4()}
                                />
                              ))}
                            </Col>
                          )}

                          {tanishqGallery3.length > 0 && (
                            <Col md={6} lg={4} className="g-3">
                              {tanishqGallery3.map((item, i) => (
                                <GatsbyImage
                                  className="w-100 mb-3"
                                  style={{ height: "310px" }}
                                  image={
                                    item.localFile.childImageSharp
                                      .gatsbyImageData
                                  }
                                  alt={item.altText}
                                  key={uuid4()}
                                />
                              ))}
                            </Col>
                          )}
                        </Row>

                        <div className="d-md-none d-grid gallery-wrapper gap-3">
                          {tanishqSection.imageGallery
                            .slice(0, 6)
                            .map((item) => (
                              <div className="gallery-item" key={uuid4()}>
                                <GatsbyImage
                                  className="w-100 mb-3"
                                  style={{ height: "100%" }}
                                  image={
                                    item.localFile.childImageSharp
                                      .gatsbyImageData
                                  }
                                  alt={item.altText}
                                />
                              </div>
                            ))}
                        </div>
                      </>
                    )}
                </Container>
              </section>
            )}
        </Layout>
      </div>
    </>
  );
};

export default CourseOverviewPage;
